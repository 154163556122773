import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import QuotationRow from './QuotationRow';
import axios from "axios";

const FollowUps = () => {
   const [allQuotations, setAllQuotations] = useState([]);
   const [allQuotationsJson, setAllQuotationsJson] = useState([]);
const [searchText, setSearchText] = useState('');
 const searchQuotations = (inputText) => {
        setSearchText(inputText);
        var filteredQuotations = allQuotationsJson.filter(item => item.subject.toLowerCase().includes(inputText) || item.email.toLowerCase().includes(inputText) || item.contact.toLowerCase().includes(inputText) || item.party.toLowerCase().includes(inputText) || item.created_by.toLowerCase().includes(inputText) || item.comments.toLowerCase().includes(inputText));
    setAllQuotations(filteredQuotations.map(item =>  <QuotationRow data={item}/>))
    }
    useEffect( () => {
		axios
          .get("/quotation/allQuotations")
          .then((resp) => {
            console.log(resp);
            const closedQuotations = resp.data.filter(item => item.status === "NEW" || item.status === "InProcess");
            setAllQuotationsJson(closedQuotations.map(item =>  item));
            setAllQuotations(closedQuotations.map(item =>  <QuotationRow data={item}/>));
          })
          .catch((error) => {
            console.log(error);
          })

	}, []);


    return (



<div class="relative overflow-x-auto shadow-md sm:rounded-lg overflow-scroll h-full">
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 overflow-scroll h-screen">
        <caption class="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            Quotations For Follow Ups
            <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400 my-4">Browse a list of FollowUps to get on track what is due</p>
<input type="text" id="lname" className="text-gray-700" name="Search Quotation" value={searchText} onChange={e=> searchQuotations(e.target.value)}></input>
        </caption>

        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Subject
                </th>
                <th scope="col" class="px-6 py-3">
                    Party
                </th>
                <th scope="col" class="px-6 py-3">
                    FollowUpDate
                </th>
                <th scope="col" class="px-6 py-3">
                    Email
                </th>
                <th scope="col" class="px-6 py-3">
                    Contact
                </th>
                <th scope="col" class="px-6 py-3">
                    CreatedBy
                </th>
                <th scope="col" class="px-6 py-3">
                    Comments
                </th>

                <th scope="col" class="px-6 py-3">
                    <span class="sr-only">Edit</span>
                </th>
            </tr>
        </thead>
        <tbody>
            {allQuotations}
        </tbody>
    </table>
</div>

    );
}
 
export default FollowUps;
import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import MainPane from './components/MainPane';
import { useEffect, useState } from 'react';
import { Link, Route, Switch } from "react-router-dom";

import CreateQuotation from './components/CreateQuotation';
import AllQuotations from './components/AllQuotation';
import QuotationDetail from './components/QuotationDetail';
import FollowUps from './components/FollowUps'
import ClosedQuotations from './components/ClosedQuotations';

export default function App() {

   const [selectedPage, setSelectedPage] = useState("FollowUp");

  const PaneToDisplay = (pageToDisplay) => {
    setSelectedPage(pageToDisplay);
    console.log(pageToDisplay);

  }


  return (
    <div className="w-screen flex h-screen">
      <div className="h-screen">
        <Navbar pageToDisplay={PaneToDisplay}/>


      </div>
      <div className="grow">

       <div className="flex flex-col h-screen">
      <h3 className="w-full text-right p-2 text-white bg-slate-800 text-sm leading-8">Sign Out</h3>
 <Switch>
        <Route exact path="/">
          <CreateQuotation />
        </Route>
        <Route exact path="/create-quotation">
          <CreateQuotation />
        </Route>
        <Route path="/all-quotations">
          <AllQuotations />
        </Route>
        <Route path="/follow-ups">
          <FollowUps />
        </Route>
        <Route path="/closed">
          <ClosedQuotations />
        </Route>
        <Route path="/quotation-detail/:quotationId">
        <QuotationDetail/>
        </Route>
      </Switch>
      </div>

    </div>
      </div>
  )
}

import React from 'react';

import { Link, Route, Switch } from "react-router-dom";

const QuotationRow = (props) => {

    const quotationRowData = props.data;

    var colorCss = ''
    if (quotationRowData.status === "Converted" || quotationRowData.status === "Dropped") {
        colorCss = "bg-green-700";
    } else {
        var currentTime = (new Date()).getTime()/1000;
        if ((quotationRowData.follow_up_date - currentTime) <= 86400) {
            colorCss = "bg-red-700";
        }
        else {
            colorCss = "bg-yellow-700"
        }
    }

    const modifyDate = () => {
        var quoteDateGMT = new Date(quotationRowData.follow_up_date*1000);
        quoteDateGMT.setHours(quoteDateGMT.getHours() + 5);
quoteDateGMT.setMinutes(quoteDateGMT.getMinutes() + 30);
        return quoteDateGMT.toISOString().substring(0,10)
    }
    return (
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class={"px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white " + colorCss}>
                    {quotationRowData.subject}
                </th>
                 <td class="px-6 py-4">
                    {quotationRowData.party}
                </td>
                 <td class="px-6 py-4">
                    {modifyDate()}
                </td>
                <td class="px-6 py-4">
                    {quotationRowData.email}
                </td>
                <td class="px-6 py-4">
                    {quotationRowData.contact}
                </td>

                <td class="px-6 py-4">
                    {quotationRowData.created_by}
                </td>
                <td class="px-6 py-4">
                    {quotationRowData.comments}
                </td>
                <td class="px-6 py-4 text-right">
                    <Link to={{pathname : `/quotation-detail/` + quotationRowData.id, state: quotationRowData}} class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</Link>
                </td>
            </tr>
    );
}
 
export default QuotationRow;
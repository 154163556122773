import React from 'react';


import { Link, Route, Switch } from "react-router-dom";

const Navbar = (props) => {

    const pageToDisplay = props.pageToDisplay;
    return (
        <div className="flex flex-col h-screen bg-slate-700">
            <h3 className="text-sm leading-8 bg-pink-500 text-white p-2" >Quotation Management</h3>
           <Link to="/"> <h2 className="text-xs text-white p-2 text-center pt-11 cursor-pointer" onClick={() => pageToDisplay("create-quotation")}>Create Quotation</h2></Link>
          <Link to="/follow-ups">  <h2 className="text-xs text-white p-2 text-center pt-11 cursor-pointer" onClick={() => pageToDisplay("follow-ups")}>Follow Ups</h2></Link>
          <Link to="/closed">   <h2 className="text-xs text-white p-2 text-center pt-11 cursor-pointer" onClick={() => pageToDisplay("closed")}>Closed</h2></Link>
            <Link to="/all-quotations"> <h2 className="text-xs text-white p-2 text-center pt-11 cursor-pointer" onClick={() => pageToDisplay("all-quotations")}>All Quotations</h2></Link>
            <Link to="/"> <h2 className="text-xs text-white p-2 text-center pt-11 cursor-pointer">Analysis</h2></Link>
        </div>
    );
}
 
export default Navbar

import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom"
import axios from "axios";

const QuotationDetail = (props) => {
  const location = useLocation();
  const quotationData = location.state;

  const modifyDate = () => {
        var quoteDateGMT = new Date(quotationData.follow_up_date*1000);
        quoteDateGMT.setHours(quoteDateGMT.getHours() + 5);
quoteDateGMT.setMinutes(quoteDateGMT.getMinutes() + 30);
        return quoteDateGMT.toISOString().substring(0,10)
    }

  const [subject, setSubject] = useState(quotationData.subject);
     const [email, setEmail] = useState(quotationData.email);
     const [contact, setContact] = useState(quotationData.contact);
     const [party, setParty] = useState(quotationData.party);
     const [quotationCreatedBy, setQuotationCreatedBy] = useState(quotationData.created_by);
     const [status, setStatus] = useState(quotationData.status);
     const [followUpDate, setFollowUpDate] = useState(modifyDate());
     const [comments, setComments] = useState(quotationData.comments);
       const [responseMessage, setResponseMessage] = useState('');
     const [responseCssClass, setResponseCssClass] = useState('');
     const updateQuotation = () => {

    var followUpDateTimestamp = followUpDate.split("-");
    followUpDateTimestamp = new Date( followUpDateTimestamp[0], followUpDateTimestamp[1] - 1, followUpDateTimestamp[2]);
    axios.post('/quotation/editQuotation',{
          id : quotationData.id,
          created_by : quotationCreatedBy,
          follow_up_date :followUpDateTimestamp.getTime()/1000,
          comments :comments,
          status:status
        })
        .then((resp) => {
          console.log(resp);
          setResponseCssClass("text-green-600 text-center");
          setResponseMessage("Quotation successfully saved !");

        })
        .catch( (error) => {
          console.log(error);
          setResponseCssClass("text-red-600 text-center");
          setResponseMessage("Some problem in saving quotation !");
        }
         )
         console.log("quotation successfully created");
    }


    return (<section class="p-6 mx-auto bg-indigo-600 rounded-md shadow-md dark:bg-gray-800 w-full h-full">
    <h1 class="text-xl font-bold text-white capitalize dark:text-white">Edit Quotation</h1>
    <div>
        <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
                <label class="text-white dark:text-gray-500">Quotation Subject</label>
                <input id="quotation-subject" type="text" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-500 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring pointer-events-none" value={subject}/>
            </div>

            <div>
                <label class="text-white dark:text-gray-500" for="emailAddress">Email Address</label>
                <input id="emailAddress" type="email" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring pointer-events-none" value={email}/>
            </div>

            <div>
                <label class="text-white dark:text-gray-500" for="contact">Contact</label>
                <input id="contact" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring pointer-events-none" value={contact}/>
            </div>

            <div>
                <label class="text-white dark:text-gray-500">Party Name</label>
                <input id="name" type="text" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" value={party}/>
            </div>
            <div>
                <label class="text-white dark:text-gray-200">Quotation Created By</label>
                <input id="name" type="text" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" value={quotationCreatedBy} onChange={e=> setQuotationCreatedBy(e.target.value)}/>
            </div>
            <div>
                <label class="text-white dark:text-gray-200" for="status">Status</label>
                <select class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" onChange={e=> setStatus(e.target.value)}>
                    <option selected={"selected" && status==="New"}>New</option>
                    <option selected={"selected" && status==="InProcess"}>InProcess</option>
                    <option selected={"selected" && status==="Converted"}>Converted</option>
                    <option selected={"selected" && status==="Dropped"}>Dropped</option>
                </select>
            </div>

            <div>
                <label class="text-white dark:text-gray-200" for="followUpDate">Next Follow Up Date</label>
                <input id="date" type="date" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" value={followUpDate} onChange={e=> setFollowUpDate(e.target.value)}/>
            </div>
            <div>
                <label class="text-white dark:text-gray-200" for="passwordConfirmation">Update Comments</label>
                <textarea id="textarea" type="textarea" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" value={comments} onChange={e=> setComments(e.target.value)}></textarea>
            </div>
            {/* <div>
                <label class="block text-sm font-medium text-white">
                Image
              </label>
              <div class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div class="space-y-1 text-center">
                  <svg class="mx-auto h-12 w-12 text-white" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <div class="flex text-sm text-gray-600">
                    <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                      <span class="">Upload a file</span>
                      <input id="file-upload" name="file-upload" type="file" class="sr-only"/>
                    </label>
                    <p class="pl-1 text-white">or drag and drop</p>
                  </div>
                  <p class="text-xs text-white">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
              </div>
            </div> */}
        </div>

        <div class="flex justify-end mt-6">
            <button class="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-pink-500 rounded-md hover:bg-pink-700 focus:outline-none focus:bg-gray-600" onClick={updateQuotation}>Save</button>
        </div>
    </div>

<p class={responseCssClass}>{responseMessage}</p>
{/* <button data-modal-target="popup-modal" data-modal-toggle="popup-modal" className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
  Toggle modal
</button> */}



</section>);
}
 
export default QuotationDetail;
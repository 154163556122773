import React, { useContext, useState } from "react";
// Initialization for ES Users
import axios from "axios";


const CreateQuotation = () => {
     const [subject, setSubject] = useState('');
     const [email, setEmail] = useState('');
     const [contact, setContact] = useState('');
     const [party, setParty] = useState('');
     const [quotationCreatedBy, setQuotationCreatedBy] = useState('');
     const [status, setStatus] = useState('New');
     const [followUpDate, setFollowUpDate] = useState('');
     const [comments, setComments] = useState('');

     const [responseMessage, setResponseMessage] = useState('');
     const [responseCssClass, setResponseCssClass] = useState('')

     const createQuotation = () => {
        console.log(subject);
    console.log(email);
    console.log(contact);
    console.log(party);
    console.log(quotationCreatedBy);
    console.log(status);
    console.log(followUpDate);
    console.log(comments);

    if(subject === '' || email ==='' || contact ==='' || party === '' || quotationCreatedBy === '' || status === '' || followUpDate === '' || comments === '') {
        setResponseCssClass("text-red-600 text-center");
          setResponseMessage("Please provide all fields!");
    } else {
    var followUpDateTimestamp = followUpDate.split("-");
    followUpDateTimestamp = new Date( followUpDateTimestamp[0], followUpDateTimestamp[1] - 1, followUpDateTimestamp[2]);
    axios.post('/quotation/createQuotation',{
          subject : subject,
          email : email,
          party : party,
          contact : contact,
          created_by : quotationCreatedBy,
          follow_up_date :followUpDateTimestamp.getTime()/1000,
          comments :comments
        })
        .then((resp) => {
          console.log(resp);
          setResponseCssClass("text-green-600 text-center");
          setResponseMessage("Quotation successfully saved !");

        })
        .catch( (error) => {
          console.log(error);
          setResponseCssClass("text-red-600 text-center");
          setResponseMessage("Some problem in saving quotation !");
        }
         )
         console.log("quotation successfully created");
    }}


    return (
        <>

<section class="p-6 mx-auto bg-indigo-600 rounded-md shadow-md dark:bg-gray-800 w-full h-full">
    <h1 class="text-xl font-bold text-white capitalize dark:text-white">Create Quotation</h1>
    <div>
        <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
                <label class="text-white dark:text-gray-200">Quotation Subject</label>
                <input id="quotation-subject" type="text" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" onChange={e => setSubject(e.target.value)}/>
            </div>

            <div>
                <label class="text-white dark:text-gray-200" for="emailAddress">Email Address</label>
                <input id="emailAddress" type="email" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" onChange={e => setEmail(e.target.value)}/>
            </div>

            <div>
                <label class="text-white dark:text-gray-200" for="contact">Contact</label>
                <input id="contact" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" onChange={e=> setContact(e.target.value)}/>
            </div>

            <div>
                <label class="text-white dark:text-gray-200">Party Name</label>
                <input id="name" type="text" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" onChange={e=> setParty(e.target.value)}/>
            </div>
            <div>
                <label class="text-white dark:text-gray-200">Quotation Created By</label>
                <input id="name" type="text" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" onChange={e=> setQuotationCreatedBy(e.target.value)}/>
            </div>
            <div>
                <label class="text-white dark:text-gray-200" for="status">Status</label>
                <select class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" onChange={e=> setStatus(e.target.value)}>
                    <option>New</option>
                    <option>InProcess</option>
                    <option>Converted</option>
                    <option>Dropped</option>
                </select>
            </div>

            <div>
                <label class="text-white dark:text-gray-200" for="followUpDate">Follow Up Date</label>
                <input id="date" type="date" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" onChange={e=> setFollowUpDate(e.target.value)}/>
            </div>
            <div>
                <label class="text-white dark:text-gray-200" for="passwordConfirmation">Comments</label>
                <textarea id="textarea" type="textarea" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" onChange={e=> setComments(e.target.value)}></textarea>
            </div>
            {/* <div>
                <label class="block text-sm font-medium text-white">
                Image
              </label>
              <div class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div class="space-y-1 text-center">
                  <svg class="mx-auto h-12 w-12 text-white" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <div class="flex text-sm text-gray-600">
                    <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                      <span class="">Upload a file</span>
                      <input id="file-upload" name="file-upload" type="file" class="sr-only"/>
                    </label>
                    <p class="pl-1 text-white">or drag and drop</p>
                  </div>
                  <p class="text-xs text-white">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
              </div>
            </div> */}
        </div>

        <div class="flex justify-end mt-6">
            <button class="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-pink-500 rounded-md hover:bg-pink-700 focus:outline-none focus:bg-gray-600" onClick={createQuotation}>Save</button>
        </div>
    </div>
<p class={responseCssClass}>{responseMessage}</p>

{/* <button data-modal-target="popup-modal" data-modal-toggle="popup-modal" className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
  Toggle modal
</button> */}



</section>



{/* <div id="defaultModal" tabindex="-1" aria-hidden="true" class="fixed top-0 right-50 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 left-56m m-48 ml-96 mt-96">
    <div class="relative w-full max-w-2xl">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Terms of Service
                </h3>
                <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <div class="p-6 space-y-6">
                <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
                </p>
                <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.
                </p>
            </div>
            <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button data-modal-hide="defaultModal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">I accept</button>
                <button data-modal-hide="defaultModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Decline</button>
            </div>
        </div>
    </div>
</div> */}



</>

    );
}

export default CreateQuotation;